<template>
  <v-navigation-drawer v-model="drawer" fixed :mini-variant="mini" :disable-resize-watcher="true" :hide-overlay="true" permanent :width="300">
    <!-- fixed header -->
    <v-list class="pa-0 nav-fixed">
      <!-- menu opener / closer -->
      <v-list-item @click.stop="mini = !mini" class="pl-2 nav-opener">
        <v-list-item-avatar class="mr-1">
          <v-btn v-if="mini" :disabled="disable" icon small>
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <v-btn v-else icon small>
            <v-icon>mdi-chevron-double-left</v-icon>
          </v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle class="main--text text-subtitle-2">{{ translations.appName }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider v-if="!mini"></v-divider>

      <!-- account info -->
      <v-list-item @click="routeTo('Dashboard')" :title="`${accountName}\n${accountEmail}`"
                   class="pl-2 nav-account-info">
        <v-list-item-avatar color="main" class="white--text">{{ accountName[0] }}</v-list-item-avatar>
        <v-list-item-content class="pl-2">
          <v-list-item-title class="title">{{ accountName }}</v-list-item-title>
          <v-list-item-subtitle>{{ accountEmail }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- scrollable section -->
    <div class="d-flex flex-column justify-space-between" style="height:100%">
      <!-- menu items -->
      <v-list dense nav class="nav-menu-items">
        <v-list-item v-for="nav in navLinks" :key="nav.meta.title"
                     @click="routeTo(nav.name)"
                     :class="[$route.meta.family === nav.meta.family ? 'active' : '']" link>
          <v-list-item-icon>
            <v-icon color="main">{{ nav.meta.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ nav.meta.title }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <!-- bottom item -->
      <div v-if="!mini" class="d-flex flex-column px-2 white--text text-subtitle-2 nav-powered-by">
        <div class="d-flex justify-space-between">
          <div class="d-flex align-end">
            <div style="width: 122px; height: 60px">
              <a href="https://tom.techgap.it" target="tom">
                <v-img :src="require(`../assets/logo-alt.webp`)"></v-img>
              </a>
            </div>

            <div class="pl-2 pb-2">{{ version }}</div>
          </div>

          <!-- logout -->
          <v-icon @click="logoutHandler" :title="translations.logout" color="orange">
            mdi-logout
          </v-icon>
        </div>

        <!-- powered by -->
        <div class="pb-2">
          {{ translations.poweredBy }}
          <a href="https://www.techgap.it" target="techgap" style="color: #FDB44B">Tech Gap Italia</a></div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'
import { translations } from '@/utils/common'
import { auth } from '@/utils/api'
import { onLogout } from '../vue-apollo'

export default {
  name: 'SideNav',

  props: {
    open: {
      type: Boolean,
      default: () => false
    },

    disable: {
      type: Boolean,
      default: () => false
    }
  },

  data: () => ({
    mini: true,
    drawer: true,
    loading: false
  }),

  watch: {
    open() {
      this.mini = this.open
    },

    mini() {
      this.$emit('open', this.mini)
    }
  },

  methods: {
    routeTo(name) {
      if (this.disable) return
      this.$router.push({ name })
      this.$emit('open', true)
    },

    async logoutHandler() {
      auth.logout()
      onLogout(this.$apollo.provider.defaultClient)
      this.$router.push({ name: 'Login' })
    }
  },

  computed: {
    translations: () => translations,

    ...mapState({
      userInfo: state => state.auth.userInfo,
      version: state => 'v ' + state?.clientVersion?.clientVersion
    }),

    accountName() {
      return this.userInfo?.account ? this.userInfo.account.name : ''
    },

    accountEmail() {
      return this.userInfo?.account ? this.userInfo.account.email : ''
    },

    navLinks() {
      return this.userInfo?.account?.capabilities
        ? this.$router.options.routes.filter(r => r.meta?.navBar && this.userInfo.account.capabilities.some(c => r.meta?.capability?.includes(c.name)))
        : []
    }
  }
}
</script>

<style scoped>
.nav-opener {
  width: 100%;
  min-height: 50px;
  height: 50px;
}

.nav-fixed {
  position: fixed;
  width: calc(100% - 1px);
  height: calc(50px + 70px);
  background: white !important;
  z-index: 100;
  cursor: default;
  box-shadow: 0 4px 10px -6px rgba(0, 0, 0, 0.2) !important;
}

.nav-account-info {
  max-width: 100%;
  height: 70px;
}

.nav-menu-items {
  margin-top: calc(50px + 70px);
}

.nav-powered-by {
  white-space: nowrap;
  cursor: default;
  background: #005792;
}

.active {
  background: rgba(0, 0, 0, 0.3);
}
</style>
