import Cookies from 'js-cookie'
import axios from './axios'
import { ACCESS_TOKEN, REFRESH_TOKEN, REFRESH_TOKEN_HEADER} from '@/vue-apollo'

export default {
  login: async (username, password) => {
    return await axios.post('login', { username, password })
  },

  logout: async () => {
    let config = {
      headers: {
        authorization: `Bearer ${Cookies.get(ACCESS_TOKEN)}`,
        [REFRESH_TOKEN_HEADER]: Cookies.get(REFRESH_TOKEN)
      }
    }
    try {
      await axios.post('delete-tokens', null, config)
    } catch (error) {
      console.error(error)
    }
  },

  refresh: async () => {
    let config = {
      headers: {
        authorization: `Bearer ${Cookies.get(ACCESS_TOKEN)}`,
        [REFRESH_TOKEN_HEADER]: Cookies.get(REFRESH_TOKEN),
      }
    }
    return await axios.get('refresh-token', config)
  }
}
