import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloLink } from 'apollo-link'
import { RetryLink } from 'apollo-link-retry'
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
import Cookies from 'js-cookie'
import conf from './utils/api/conf'
import { auth } from '../src/utils/api'
import store from './store'
import { onError } from 'apollo-link-error'
import { fromPromise } from 'apollo-link'
import router from '@/router';

export const ACCESS_TOKEN = 'TOM-token'
export const REFRESH_TOKEN = 'TOM-rtoken'
export const REFRESH_TOKEN_HEADER = "X-TOM-TOKEN"

export let clientApollo

Vue.use(VueApollo)

// auth link with custom headers
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'authorization': `Bearer ${Cookies.get(ACCESS_TOKEN)}` || null,
      'tom-client-version': store.state.clientVersion.clientVersion || null
    }
  })
  return forward(operation)
})

let isRefreshing = false
let pendingRequests = []

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback())
  pendingRequests = []
}

const refreshTokens = async () => {
  try {
    const {accessToken, refreshToken} = await auth.refresh()
    Cookies.set(ACCESS_TOKEN, accessToken, {sameSite: 'strict'})
    Cookies.set(REFRESH_TOKEN, refreshToken, {sameSite: 'strict'})
    return true
  } catch (error) {
    console.error('Error refreshing tokens')
    await auth.logout()
    onLogout()
    router.push('/login')
    return false
  }
}

// error handling
const errorLink = onError(
  ({networkError, operation, forward}) => {
    if (networkError) {

      // handle Authorization errors
      if (networkError.statusCode === 401) {
        let forward$;

        // refresh tokens only on first error
        if (!isRefreshing) {
          isRefreshing = true;
          forward$ = fromPromise(
            refreshTokens()
              .then((data) => {
                resolvePendingRequests();
                isRefreshing = false;
                return data;
              })
              .catch(() => {
                pendingRequests = [];
                return;
              })
              .finally(() => {
                isRefreshing = false;
              })
          ).filter((value) => {
            return Boolean(value)
          })
        } else {
          // add all other failed requests to a list
          forward$ = fromPromise(
            new Promise((resolve) => {
              pendingRequests.push(() => resolve());
            })
          )
        }

        // retry all operations with the new refreshed token
        return forward$.flatMap(() => {
          const oldHeaders = operation.getContext().headers
          operation.setContext({
            headers: {
              ...oldHeaders,
              "authorization": Cookies.get(ACCESS_TOKEN)
            }
          })
          return forward(operation)
        });
      }
    }
});

// retry link
const retryLink = new RetryLink({
  delay: {
    initial: 200
  },

  attempts: {
    max: 2,
    retryIf: (error) => {

      // when new version is available show modal to reload page (checked on top of 'tom-client-version' header)
      if (error.statusCode === 409) {
        store.dispatch('clientVersionOpenModal')
        return false
      }
      return false
    }
  }
})

// apollo client
const defaultOptions = {
  httpEndpoint: conf.value('VUE_APP_GRAPHQL_HTTP'),
  tokenName: ACCESS_TOKEN,
  persisting: false,
  websocketsOnly: false,
  ssr: false,
  link: ApolloLink.from([errorLink, authLink, retryLink])
}

// call this in the Vue app file
export function createProvider(options = {}) {
  // create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options
  })
  apolloClient.wsClient = wsClient

  // set the client to be invoked outside Vue components
  clientApollo = apolloClient

  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network'
      }
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    }
  })
}

// manually call this when user log in
export async function onLogin(apolloClient, accessToken, refreshToken) {
  if (accessToken && refreshToken) {
    Cookies.set(ACCESS_TOKEN, accessToken, {sameSite: 'strict'})
    Cookies.set(REFRESH_TOKEN, refreshToken, {sameSite: 'strict'})
  }

  if (apolloClient) {
    if (apolloClient.wsClient) {
      restartWebsockets(apolloClient.wsClient)
    }
    await apolloClient.resetStore()
  }
}

// manually call this when user log out
export async function onLogout(apolloClient) {
  store.dispatch('setUserInfo', {})

  if (Cookies.get(ACCESS_TOKEN)) {
    Cookies.remove(ACCESS_TOKEN)
  }
  if (Cookies.get(REFRESH_TOKEN)) {
    Cookies.remove(REFRESH_TOKEN)
  }

  if (apolloClient) {
    if (apolloClient.wsClient) {
      restartWebsockets(apolloClient.wsClient)
    }
    await apolloClient.resetStore()
  }
}
